import React from 'react';
import { Params } from 'react-router-dom';
import { SimpleForm } from 'react-admin';
import Button from '@mui/material/Button';
import { TimeSelection } from '../timeSelection';
import s from './index.module.scss';

interface IPropsAdministratorsCreate {
  params: Readonly<Params<string>>;
}


// @ts-ignore
export const SettingsDetail = ({ params }: IPropsAdministratorsCreate) => {
  // Здесь получите данные о настройке с указанным id
  const { id } = params;
  return (
    <div className={s.wrapper}>
      { id === 'buffertime' ?
        <>
          <TimeSelection params={params} />
        </>
        :
        null
      }
    </div>
  );
};
