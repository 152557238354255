/* eslint-disable no-param-reassign */
import React, { FC } from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import { AuthAPI } from 'api/payway/auth';
import { CustomLayout } from 'layout/LayoutView';
import administrators from 'entities/administrators';
import clients from 'entities/clients';
import settings from 'entities/settings';
import MyNotification from 'notification';
import { dataProvider } from 'api/payway';

export const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  options.headers.set('X-Custom-Header', 'foobar');
  return fetchUtils.fetchJson(url, options);
};

export const AdminView: FC = () => (
  <Admin
    notification={MyNotification}
    // loginPage={MyLoginPage}
    // dataProvider={dataProvider}
    dataProvider={dataProvider}
    authProvider={AuthAPI}
    layout={CustomLayout}
  >
    <Resource name="admin" {...administrators} />
    <Resource name="clients" {...clients} />
    <Resource name="settings" {...settings} />
  </Admin>
);

export default AdminView;
