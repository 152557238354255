import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

interface IBufferTime {
  id: string;
  bufferTime: number;
}

export const adminAPI = createApi({
  reducerPath: 'adminAPI',
  // https://api.payway.asap-lp.ru - stend
  // https://api.payway-test.asap-lp.ru/ - test
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.payway-test.asap-lp.ru/' }),
  tagTypes: ['Buffer'],
  endpoints: (build) => ({
    fetchBufferTime: build.query<IBufferTime[], void>({
      query: () => ({
        url: '/api/admin/buffertime'
      }),
      providesTags: result => ['Buffer']
    }),

    updateBufferTime: build.mutation<IBufferTime, IBufferTime>({
      query: (buffer) => ({
        url: '/api/admin/buffertime',
        method: 'PUT',
        body: buffer
      }),
      invalidatesTags: ['Buffer']
    })
  })
});
