import SettingsIcon from '@mui/icons-material/Settings';
import { SettingsPostList } from './SettingsList';
import { SettingsPostShow } from './SettingsShow';
import { SettingsCreate } from './SettingsCreate';
import { SettingsPostEdit } from './SettingsEdit';

export default {
  list: SettingsPostList,
  show: SettingsPostShow,
  edit: SettingsPostEdit,
  create: SettingsCreate,
  options: { label: 'Settings' },
  icon: SettingsIcon
};
