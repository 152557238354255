import React from 'react';
import { Edit, useNotify, useRefresh } from 'react-admin';
import { dataProvider } from 'api/payway';
import { Params, useParams } from 'react-router-dom';
import { SettingsDetail } from './components/settingsDetail';

interface IPropsAdministratorsCreate {
  params: Readonly<Params<string>>;
}



export const SettingsPostEditView = ({ params }: IPropsAdministratorsCreate) => {
  console.log('');
  return (
  // <SimpleForm>
  //   <DateRange />
  //   {/* <TextInput source="name" /> */}
  //   {/* <TextInput source="phone" /> */}
  //   {/* <TextInput defaultValue="" source='passwordField' /> */}
  //   {/* <TextInput type="email" source="email" /> */}
  //   {/* <ImageInput source="picture" label="Pictures"> */}
  //   {/*  <ImageField source="src" title="title" /> */}
  //   {/* </ImageInput> */}
  //   {/* <BooleanInput label="Remember" source="remember" /> */}
  // </SimpleForm>
    <>
      <SettingsDetail params={params} />
    </>
  );
};


export const SettingsPostEdit = (record: any) => {
  const params = useParams();
  return (
  <>
      <SettingsPostEditView params={params} />
  </>
  );
};

