import React, { useState } from 'react';
import { BooleanInput, Create, ImageField, ImageInput, SimpleForm, TextInput, useNotify } from 'react-admin';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import { Checkbox } from '@material-ui/core';

interface IPropsClientsCreate {}

export const SettingsCreate = (props: IPropsClientsCreate) => {
  const [checked, setChecked] = useState(false);
  const notify = useNotify();
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  return (
    <Create redirect={'list'} {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="password" />
        <TextInput source="phone" />
        <TextInput type="email" source="email" />
        <ImageInput source="picture" label="Pictures">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput label="Remember" source="remember" />
      </SimpleForm>
    </Create>
  );
};
