import React from 'react';
import { CreateButton, Edit, EditButton, TopToolbar } from 'react-admin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { RouterLink } from 'router/components/routerLink';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import s from './index.module.scss';

const SettingsActions = () => (
  <TopToolbar>
    <CreateButton label="Create a client"/>
    <EditButton icon={<EditIcon/>} label="Edit a client"/>
  </TopToolbar>
);

export const SettingsListLayout = () =>
  <div className={s.wrapper}>
    <ul>
      <li>
        <RouterLink to={'/settings/buffertime'}>
          <Button variant="outlined" type={'button'}>
            <AccessTimeIcon/>
            <p>Set buffer time</p>
          </Button>
        </RouterLink>
      </li>
      {/* Добавьте ссылки на другие настройки */}
    </ul>
  </div>;

export const SettingsPostList = () => (
  <div className={s.wrapper__list}>
    <SettingsListLayout/>
  </div>
);
