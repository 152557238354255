/* eslint-disable no-restricted-syntax,no-empty */
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { dataProvider } from 'api/payway';
import { useNotify, useRefresh } from 'react-admin';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { adminAPI } from 'services/AdminService';
import { TextField, Grid } from '@mui/material';
import s from './index.module.scss';

interface ITImeSelection {
  params: any;
}

export const TimeSelection = ({ params }: ITImeSelection) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const { data, isLoading: isGetBufferLoading, error } = adminAPI.useFetchBufferTimeQuery();
  const [updateBufferTime, {
    data: updatedBufferTime,
    error: updatedError,
    isError: isUpdatedError,
    isSuccess: isUpdatedSuccess,
    isLoading: isupdatedLoading
  }] = adminAPI.useUpdateBufferTimeMutation();

  const [minutes, setMinutes] = React.useState<Dayjs | null>(null);
  const [hours, setHours] = React.useState<Dayjs | null>(null);
  const [months, setMonths] = React.useState<any>('');
  const [weeks, setWeeks] = React.useState<any>('');
  const [days, setDays] = React.useState<any>('');
  const [years, setYears] = React.useState<any>('');
  const [timeInMilliseconds, setTimeInMilliseconds] = useState<any>(0);
  const [combineTime, setCombineTime] = useState<any>({});

  function convertMillisecondsToTime(milliseconds: number) {
    const secondss = Math.floor(milliseconds / 1000);
    const minutess = Math.floor(secondss / 60);
    const hourss = Math.floor(minutess / 60);
    const dayss = Math.floor(hourss / 24);
    const weekss = Math.floor(dayss / 7);
    const monthss = Math.floor(dayss / 30); // Это очень приближенное значение месяцев
    const yearss = Math.floor(dayss / 365); // Это очень приближенное значение лет

    return {
      yearss,
      monthss,
      weekss,
      dayss: dayss % 30, // Оставшиеся дни после вычета полных месяцев
      hourss: hourss % 24, // Оставшиеся часы после вычета полных дней
      minutess: minutess % 60 // Оставшиеся минуты после вычета полных часов
    };
  }

  const handleSendClick = () => {

    if (data?.length) {
      updateBufferTime({ id: data[0]?.id, bufferTime: timeInMilliseconds });
    }
  };

  const handleDaysKeyDown = (event: any) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  const handleWeeksChange = (event: any) => {
    const newValue = event.target.value;
    const weeksInMilliseconds = dayjs().add(newValue, 'weeks').diff(dayjs(), 'millisecond');
    setWeeks(newValue);

    setCombineTime({ ...combineTime, weeks: weeksInMilliseconds });
  };

  const handleDaysChange = (event: any) => {
    const newValue = event.target.value;
    const daysInMilliseconds = dayjs().add(newValue, 'day').diff(dayjs(), 'millisecond');
    setDays(newValue);

    setCombineTime({ ...combineTime, days: daysInMilliseconds });
  };
  const handleMonthsChange = (event: any) => {
    const newValue = event.target.value;

    const monthsInMilliseconds = dayjs().add(newValue, 'month').diff(dayjs(), 'millisecond');
    setCombineTime({ ...combineTime, months: monthsInMilliseconds });
    setMonths(newValue);
  };

  const handleYearsChange = (event: any) => {
    const newValue = event.target.value;

    const yearsInMilliseconds = dayjs().add(newValue, 'year').diff(dayjs(), 'millisecond');

    setCombineTime({ ...combineTime, years: yearsInMilliseconds });
    setYears(newValue);
  };
  const handleHoursChange = (newValue: any) => {
    const hoursInMilliseconds = dayjs().add(newValue?.$H || 0, 'hour').diff(dayjs(), 'millisecond');
    setCombineTime({ ...combineTime, hours: hoursInMilliseconds });
  };

  const handleMinutesChange = (newValue: any) => {
    const minutesInMilliseconds = dayjs().add(newValue?.$m || 0, 'minute').diff(dayjs(), 'millisecond');
    setCombineTime({ ...combineTime, minutes: minutesInMilliseconds });
  };
  useEffect(() => {
    if (!isGetBufferLoading && data?.length) {
      const totalMilliseconds = Object.values(combineTime).reduce(
        // @ts-ignore
        (accumulator: number, currentValue: number) => accumulator + currentValue,
        0
      );
      // @ts-ignore
      setTimeInMilliseconds(totalMilliseconds);
    }

  }, [combineTime, isGetBufferLoading]);

  useEffect(() => {
    if (isUpdatedError) {
      // @ts-ignore
      notify(updatedError?.data?.error || 'Something went wrong', { type: 'warning' });
    }

    if (isUpdatedSuccess) {
      notify('Time changed successfully', { type: 'success' });
      refresh();
    }
  }, [isUpdatedError, isUpdatedSuccess]);

  useEffect(() => {
    if (!isGetBufferLoading && data?.length) {
      const currentDataTime = convertMillisecondsToTime(data[0]?.bufferTime);
      setDays(currentDataTime.dayss || '');
      setWeeks(currentDataTime.weekss || '');
      setMonths(currentDataTime.monthss || '');
      setYears(currentDataTime.yearss || '');
      // @ts-ignore
      setMinutes(dayjs()?.minute(currentDataTime?.minutess)?.$m ? dayjs()?.minute(currentDataTime?.minutess) : null);
      // @ts-ignore
      setHours(dayjs()?.hour(currentDataTime?.hourss)?.$H ? dayjs()?.hour(currentDataTime?.hourss) : null);
    }
  }, [isGetBufferLoading]);

  useEffect(() => {
    if (!isGetBufferLoading && data?.length) {
      setTimeInMilliseconds(data[0]?.bufferTime);
      const currentDataTime = convertMillisecondsToTime(data[0]?.bufferTime);
      setCombineTime({
        years: dayjs().add(currentDataTime.yearss, 'year').diff(dayjs(), 'millisecond'),
        months: dayjs().add(currentDataTime.monthss, 'month').diff(dayjs(), 'millisecond'),
        weeks: dayjs().add(currentDataTime.weekss, 'week').diff(dayjs(), 'millisecond'),
        days: dayjs().add(currentDataTime.dayss, 'day').diff(dayjs(), 'millisecond'),
        hours: dayjs().add(currentDataTime.hourss, 'hour').diff(dayjs(), 'millisecond'),
        minutes: dayjs().add(currentDataTime.minutess, 'minute').diff(dayjs(), 'millisecond')
      });
    }
  }, [isGetBufferLoading]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <h2 className={s.title}>Set buffer time here</h2>

      {!isGetBufferLoading && data?.length ?
        <div className={s.buffer}>
          <h4>Current buffer time</h4>

          <div className={s.buffer__items}>
            <div className={s.buffer__item}>
              <h6>Minutes</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).minutess}</p>
            </div>

            <div className={s.buffer__item}>
              <h6>Hours</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).hourss}</p>
            </div>
            <div className={s.buffer__item}>
              <h6>Days</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).dayss}</p>
            </div>
            <div className={s.buffer__item}>
              <h6>Weeks</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).weekss}</p>
            </div>
            <div className={s.buffer__item}>
              <h6>Months</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).monthss}</p>
            </div>
            <div className={s.buffer__item}>
              <h6>Years</h6>
              <p>{convertMillisecondsToTime(data[0]?.bufferTime).yearss}</p>
            </div>
          </div>
        </div>
        :
        null
      }

      <DemoContainer components={['TimeField', 'TimeField', 'TextField', 'TextField', 'TextField', 'TextField']}>
        <Grid className={s.grid} container spacing={2}>
          <Grid item>
            <TimeField
              label="Minutes"
              value={minutes}
              onChange={handleMinutesChange}
              format="mm"
            />
          </Grid>

          <Grid item>
            <TimeField
              label="Hours"
              value={hours}
              onChange={handleHoursChange}
              format="HH"
            />
          </Grid>

          <Grid item>
            <TextField
              label="Days"
              type={'number'}
              value={days}
              onChange={handleDaysChange}
              onKeyDown={handleDaysKeyDown}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Weeks"
              type={'number'}
              value={weeks}
              onChange={handleWeeksChange}
              onKeyDown={handleDaysKeyDown}
            />
          </Grid>

          <Grid item>
            <TextField
              label="Months"
              type={'number'}
              value={months}
              onChange={handleMonthsChange}
              onKeyDown={handleDaysKeyDown}
            />
          </Grid>


          <Grid item>
            <TextField
              label="Years"
              type={'number'}
              value={years}
              onChange={handleYearsChange}
              onKeyDown={handleDaysKeyDown}
            />
          </Grid>
        </Grid>
      </DemoContainer>

      <Button className={s.submit} variant="contained" disabled={!timeInMilliseconds} onClick={handleSendClick}>
        <p>Save</p>
      </Button>
    </LocalizationProvider>
  );
};
