/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
import { stringify } from 'query-string';
import RestAPI from 'api/rest';
import { httpClient } from 'admin';
import { apiUrl } from 'constants/index';

// export const ClientsAPI = {
//   getList: async (resource: any: any, params: any: any) => {
//     const { page } = params: any.pagination;
//     const quantity = params: any.pagination.perPage;
//     const searchByLot = params: any.filter.search;
//     const searchById = params: any.filter.byId;
//     const query = {
//       page,
//       quantity,
//       lotid: searchById,
//       lotnumber: searchByLot
//     };
//     return RestAPI.get(`/api/${resource: any}/all/?${stringify(query)}`).then(({ data }) => ({
//       data: data.clients,
//       total: data.meta.total
//     }));
//   },

//   create: async (resource: any: any, params: any: any) => {
//     // console.log(params: any);
//     console.log(resource: any, params: any);
//     // await RestAPI.post(`/api/${resource: any}`, { ...params: any.data });
//     // return { data: { ...params: any.data, id: Date.now() } };

//     // return { data: { ...params: any.data, id: Date.now() } };
//     // return { data: { ...params: any.data, id:} };

//     // return RestAPI.post(`/api/${resource: any}/create/?${stringify(query)}`).then(({ data }) => ({ data: data.admins, total: data.meta.total }));
//   }
// };

const baseURL = process.env.REACT_APP_API_URL || '';

export const dataProviderSettings = {

  createBufferTime: 'create',

  getBufferTime: 'all',

  // getList: (resource: any: any, params: any: any) => {
  //   const { page, perPage } = params: any.pagination;
  //   const { field, order } = params: any.sort;
  //   const query = {
  //     page: page - 1,
  //     quantity: perPage
  //   };
  //
  //   // console.log(httpClient(url).then(({ headers, json }: any) => console.log(json)) );
  //
  //   // return RestAPI.get(`/api/${resource: any}/all/?${stringify(query)}`).then(({ data }: any) => ({
  //   //   data: data.clients,
  //   //   total: data.meta.total
  //   // }));
  //   return RestAPI.get(`/api/${resource: any}/all/?${stringify(query)}`).then(({ data }: any) => ({
  //     data: data.clients,
  //     total: data.meta.total
  //   }));
  // },
  //
  // getOne: (resource: any: any, params: any: { id: any }) => RestAPI.get(`/api/${resource: any}/${params: any.id}`).then(({ data }: any) => (
  //   { data, id: params: any?.id }
  // )),
  //
  // changeClientActivityStatus: (params: any: any) => {
  //  
  //   RestAPI.delete(`/api/clients/${params: any.id}`);
  //
  //   return { data: true };
  // },
  //
  // getMany: (resource: any: any, params: any: any) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params: any.ids })
  //   };
  //   const url = `${baseURL}/${resource: any}?${stringify(query)}`;
  //   return httpClient(url).then(({ json }) => ({
  //     data: json.map((resource: any: { _id: any }) => ({ ...resource: any, id: resource: any._id }))
  //   }));
  // },
  //
  // getManyReference: (
  //   resource: any: any,
  //   params: any: {
  //     pagination: { page: any; perPage: any };
  //     sort: { field: any; order: any };
  //     filter: any;
  //     target: any;
  //     id: any;
  //   }
  // ) => {
  //   const { page, perPage } = params: any.pagination;
  //   const { field, order } = params: any.sort;
  //   const query = {
  //     sort: JSON.stringify([field, order]),
  //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
  //     filter: JSON.stringify({
  //       ...params: any.filter,
  //       [params: any.target]: params: any.id
  //     })
  //   };
  //   const url = `${`${process.env.REACT_APP_API_URL}/api`}/${resource: any}?${stringify(query)}`;
  //
  //   return httpClient(url).then(({ headers, json }: any) => ({
  //     data: json.map((resource: any: { _id: any }) => ({ ...resource: any, id: resource: any._id })),
  //     total: parseInt(headers.get('content-range').split('/').pop(), 10)
  //   }));
  // },
  //
  // update: (resource: any: any, params: any: { id: any; data: any }) => {
  //   const newData = { ...params: any.data, password: params: any.data.passwordField };
  //   delete newData.passwordField;
  //   console.log(newData);
  //   return (httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource: any}/${params: any.id}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(newData)
  //   }).then(({ data }: any) => ({ data, id: params: any.id })));
  //
  // },
  //
  // updateMany: (resource: any: any, params: any: { ids: any; data: any }) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params: any.ids })
  //   };
  //   return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource: any}?${stringify(query)}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(params: any.data)
  //   }).then(({ json }) => ({ data: json }));
  // },
  //
  // create: (resource: any: any, params: any: { data: any }) =>
  //   RestAPI.post(`/api/${resource: any}`, params: any.data).then(({ data }: any) => 
  //     // console.log(data);
  //     // console.log(params: any.data);
  //     ({ data: { ...params: any.data, id: data.id } })
  //   ),
  //
  // // httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource: any}/create`, {
  // //   method: 'POST',
  // //   body: JSON.stringify(params: any.data)
  // // }).then(({ json }) => {
  // //   console.log(json);
  // //   return { data: { ...params: any.data, id: json.id } };
  // // }),
  //
  // delete: async (params: any: { id: any }) => {
  //   RestAPI.delete(`/api/clients/${params: any.id}`);
  //   return { data: true };
  // },
  //
  // deleteMany: (resource: any: any, params: any: any) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params: any.ids })
  //   };
  //   return httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource: any}?${stringify(query)}`, {
  //     method: 'DELETE',
  //     body: JSON.stringify(params: any.data)
  //   }).then(({ json }) => ({ data: json }));
  // }
  getList: (resource: any, params: any) => Promise.resolve({ data: [], total: 0 }),
  getOne: (resource: any, params: any) => Promise.resolve({ data: { id: null } }),
  getMany: (resource: any, params: any) => Promise.resolve({ data: [] }),
  getManyReference: (resource: any, params: any) => Promise.resolve({ data: [], total: 0 }),
  create: (resource: any, params: any) => Promise.resolve({ data: {} }),
  update: (resource: any, params: { id: any; data: any }) =>
    httpClient(`${`${process.env.REACT_APP_API_URL}/api`}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ ...json, id: json._id })),
  updateMany: (resource: any, params: any) => Promise.resolve({ data: {} }),
  delete: (resource: any, params: any) => Promise.resolve({ data: {} }),
  deleteMany: (resource: any, params: any) => Promise.resolve({ data: {} }),
};
