import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { adminAPI } from 'services/AdminService';
// import userReducer from './reducers/UserSlice';

const rootReducer = combineReducers({
  // userReducer,
  [adminAPI.reducerPath]: adminAPI.reducer,
});

export const setupStore = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminAPI.middleware)
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
